import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import MainLayout from '../components/layouts/MainLayout'
import Footer from '../components/footer'
import SEO from '../components/seo'

import './reponse.css'

function Reponse() {
  const data = useStaticQuery(graphql`
    {
      lights: file(relativePath: { eq: "title_lights.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      response: file(relativePath: { eq: "response.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <MainLayout activeMenu="response">
      <SEO title="Donner sa réponse" />
      <GatsbyImage
        image={data.lights.childImageSharp.gatsbyImageData}
        className="page-title-lights"
        alt="Dessin d'une guirlande de lumières"
      />
      <h1 className="page-title">
        <span className="first-line">Donner</span>
        <br />
        <span className="middle-line">SA REPONSE</span>
        <br />
        <span className="last-line">(tu viens ou tu viens ?)</span>
      </h1>

      <p className="paragraph">
        Voilà ! Maintenant que vous avez toutes les infos, on attend une réponse
        pour savoir si vous venez faire la fête avec nous ;)
      </p>
      <p className="paragraph">
        Pour répondre, c&apos;est simple, remplissez le formulaire de réponse
        juste en dessous :
      </p>

      <GatsbyImage
        image={data.response.childImageSharp.gatsbyImageData}
        alt="Dessin de chats qui jouent avec les feuilles d'un calendrier indiquant la date du 15 Juillet."
        className="response-image"
      />

      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSfzeB9utzmW-GoOI6gfg80tMN9-iiUNz6FxO-TJygNz7ctWVQ/viewform?usp=pp_url"
        target="_blank"
        rel="noopener noreferrer"
        className="response-btn"
      >
        Remplir le formulaire
      </a>

      {/* <p className="paragraph">
        <b>MERCI À TOUS</b>
        <br />
        Nous avons bien reçu toutes vos réponses !<br />
        On revient vers vous avec plus d&apos;infos dans les prochaines
        semaines, surveillez vos boîtes mail ;)
      </p> */}

      <Footer />
    </MainLayout>
  )
}

export default Reponse
