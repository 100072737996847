import React from 'react'

import './footer.css'

function Footer() {
  return (
    <footer>
      <span style={{ textDecoration: 'line-through' }}>&copy;2020</span> ah
      non... &copy;2021 |{' '}
      <a href="https://kakine.fr" target="_blank" rel="noopener noreferrer">
        Kakine
      </a>{' '}
      &{' '}
      <a
        href="https://github.com/yomansk8"
        target="_blank"
        rel="noopener noreferrer"
      >
        Yomansk8
      </a>
    </footer>
  )
}

export default Footer
